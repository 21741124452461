import React, { FC, useEffect } from 'react'
import { InvestmentProfileCriteria } from './InvestmentProfileCriteria'
import { Button, FormGroup, Icon, InputGroup, Intent } from '@blueprintjs/core'
import styles from './InvestmentProfileForm.module.scss'
import * as Backend from '../../logic/Backend'
import { InvestmentProfileData, NewInvestmentProfileRequest } from '../../logic/Backend'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { OmniFilter } from '../Filtering/OmniFilter'
import { NumberRangePickerState } from '../Filtering/Filters/NumberRangePicker'
import { MultiSelectState } from '../Filtering/Filters/MultiSelect'
import { rangeFromBackend } from '../../logic/ValueFormatters'
import { allFiltersMap } from '../../ui/components/Filters'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { IndustryGroup } from '../../resources/hooks/useNestedIndustries'

export interface InvestmentProfileFormProps {
  onSubmit: (successful: boolean) => void
  onCancel?: () => void
  toEdit?: InvestmentProfileData
}

const InvestmentProfileForm: FC<WithNamespaces & InvestmentProfileFormProps> = ({ onSubmit, onCancel, toEdit }) => {
  const industryState: IndustryGroup[] = useSelector((state: any) => state.industryState.unparsedIndustryData)

  function filterObjectsById(objects: IndustryGroup[], ids?: number[]) {
    return objects.filter((obj) => ids?.includes(obj.id))
  }
  const filteredObjects = filterObjectsById(industryState, toEdit?.industries)
  // const filteredObjectsForBackend = filteredObjects.map(({ id, ...rest }) => rest)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: toEdit?.name ?? '',
      industries: filteredObjects as any,
      industries_weight: toEdit?.industries_weight ?? 3,
      countries: toEdit?.countries,
      countries_weight: toEdit?.countries_weight ?? 3,
      funding_types: toEdit?.funding_types,
      funding_types_weight: toEdit?.funding_types_weight ?? 3,
      total_funding_usd_int8range: rangeFromBackend(toEdit?.total_funding_usd_int8range),
      total_funding_usd_int8range_weight: toEdit?.total_funding_usd_int8range_weight ?? 3,
      employee_count_int4range: rangeFromBackend(toEdit?.employee_count_int4range),
      employee_count_int4range_weight: toEdit?.employee_count_int4range_weight ?? 3,
      founded_on_int4range: rangeFromBackend(toEdit?.founded_on_int4range),
      founded_on_int4range_weight: toEdit?.founded_on_int4range_weight ?? 3,
    },
    validationSchema: yup.object({
      name: yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      const ip: NewInvestmentProfileRequest = {
        ...values,
        total_funding_usd_int8range: forBackend(values.total_funding_usd_int8range),
        employee_count_int4range: forBackend(values.employee_count_int4range),
        founded_on_int4range: forBackend(values.founded_on_int4range),
      }
      try {
        if (toEdit) {
          await Backend.editInvestmentProfile({ ...ip, id: toEdit.id })
        } else {
          await Backend.newInvestmentProfile(ip)
        }
        onSubmit(true)
      } catch (error) {
        if (error.message.match(/status: (\d+)/)?.[1] === '417') {
          formik.setFieldError('name', `${t('This name is already taken')}`)
        } else {
          onSubmit(false)
        }
      }
    },
  })

  useEffect(() => {
    const errors = Object.keys(formik.errors)
    if (errors.length > 0) {
      document.getElementById(errors[0])?.focus()
    }
  }, [formik])

  function intent(key: keyof typeof formik.values): Intent {
    return formik.touched[key] && Boolean(formik.errors[key]) ? 'danger' : 'none'
  }

  function helperText(key: keyof typeof formik.values): string | undefined {
    return formik.touched[key] ? (formik.errors[key] as string) : undefined
  }

  function forBackend(state?: NumberRangePickerState): string | undefined {
    if (state === undefined) {
      return undefined
    }
    return [state.start, state.end].map((x) => x?.toString() ?? 'Any').join('...')
  }

  return (
    <form className={styles.root}>
      <div className={styles.header}>
        <Icon icon={'info-sign'} iconSize={15} color={'#9b61dd'} />
        <div>
          <p>
            {t(
              'The investment profile is your first step to enabling Kurrant’s AI-driven recommendations. By applying your preferences to our database of millions+ companies, Kurrant will make the best use of your unique investment thesis, learning from your ratings and updating our AI company rankings regularly.'
            )}
          </p>
          <div>
            {t('You can create as many investment profiles as you like, but be sure to select at least one criteria when creating an investment profile.')}
          </div>
        </div>
      </div>
      <FormGroup intent={intent('name')} className={styles.profileName} label={t('Profile Name')} labelFor="name" helperText={helperText('name')}>
        <InputGroup intent={intent('name')} placeholder={'Enter your profile name…'} id="name" value={formik.values.name} onChange={formik.handleChange} />
      </FormGroup>
      <InvestmentProfileCriteria
        title={`${t('Industries')}`}
        initialWeight={formik.values.industries_weight}
        onChangeWeight={(w) => formik.setFieldValue('industries_weight', w)}
        description={`${t('Select the industries and/or sub-industries in which you’re interested.')}`}
      >
        <OmniFilter
          filter={allFiltersMap['industries']}
          searchLogic={'containsAny'}
          standalone={true}
          noSwitch={true}
          fixed={true}
          filterState={{
            selectedNestedIndustryNames: [] as any,
          }}
          onChange={(state) => {
            return formik.setFieldValue('industries', (state.filterState as MultiSelectState).selectedNestedIndustryNames)
          }}
        />
      </InvestmentProfileCriteria>
      <InvestmentProfileCriteria
        initialWeight={formik.values.countries_weight}
        onChangeWeight={(w) => formik.setFieldValue('countries_weight', w)}
        title={`${t('Countries')}`}
        description={`${t('Select the countries in which you want your target companies to be located.')}`}
      >
        <OmniFilter
          filter={allFiltersMap['country_iso_3166_1_alpha_3']}
          searchLogic={'containsAny'}
          standalone={true}
          noSwitch={true}
          fixed={true}
          filterState={{ selectedItems: formik.values.countries }}
          onChange={(state) => formik.setFieldValue('countries', (state.filterState as MultiSelectState).selectedItems)}
        />
      </InvestmentProfileCriteria>
      <InvestmentProfileCriteria
        initialWeight={formik.values.funding_types_weight}
        onChangeWeight={(w) => formik.setFieldValue('funding_types_weight', w)}
        title={`${t('Funding Stages')}`}
        description={`${t('Select the preferred funding stage(s) of your target companies.')}`}
      >
        <OmniFilter
          filter={allFiltersMap['latest_funding_type']}
          searchLogic={'containsAny'}
          standalone={true}
          noSwitch={true}
          fixed={true}
          filterState={{ selectedItems: formik.values.funding_types }}
          onChange={(state) => formik.setFieldValue('funding_types', (state.filterState as MultiSelectState).selectedItems)}
        />
      </InvestmentProfileCriteria>
      <InvestmentProfileCriteria
        initialWeight={formik.values.total_funding_usd_int8range_weight}
        onChangeWeight={(w) => formik.setFieldValue('total_funding_usd_int8range_weight', w)}
        title={`${t('Total Funding Amount')}`}
        description={`${t('Select the prior total funding amount of your target companies.')}`}
      >
        <OmniFilter
          filter={allFiltersMap['total_funding_usd_bigint']}
          searchLogic={'between'}
          standalone={true}
          noSwitch={true}
          fixed={true}
          filterState={formik.values.total_funding_usd_int8range}
          onChange={(state) => formik.setFieldValue('total_funding_usd_int8range', state.filterState as NumberRangePickerState)}
        />
      </InvestmentProfileCriteria>
      <InvestmentProfileCriteria
        initialWeight={formik.values.employee_count_int4range_weight}
        onChangeWeight={(w) => formik.setFieldValue('employee_count_int4range_weight', w)}
        title={`${t('Employee Count')}`}
        description={`${t('Select the company size of your target companies.')}`}
      >
        <OmniFilter
          filter={allFiltersMap['employee_count_int4range']}
          searchLogic={'between'}
          standalone={true}
          noSwitch={true}
          fixed={true}
          filterState={formik.values.employee_count_int4range}
          onChange={(state) => formik.setFieldValue('employee_count_int4range', state.filterState as NumberRangePickerState)}
        />
      </InvestmentProfileCriteria>
      <InvestmentProfileCriteria
        initialWeight={formik.values.founded_on_int4range_weight}
        onChangeWeight={(w) => formik.setFieldValue('founded_on_int4range_weight', w)}
        title={`${t('Year Founded')}`}
        description={`${t('Select the year founded range for your target companies.')}`}
      >
        <OmniFilter
          filter={allFiltersMap['founded_on_date']}
          searchLogic={'between'}
          standalone={true}
          noSwitch={true}
          fixed={true}
          filterState={formik.values.founded_on_int4range}
          onChange={(state) => formik.setFieldValue('founded_on_int4range', state.filterState as NumberRangePickerState)}
        />
      </InvestmentProfileCriteria>
      <div className={styles.button}>
        <Button onClick={() => onCancel?.()} type={'button'} fill={false} text={'Cancel'} disabled={formik.isSubmitting} />
        <Button
          onClick={formik.submitForm}
          type={'button'}
          intent={'primary'}
          fill={false}
          text={toEdit ? t('Edit') : t('Create')}
          loading={formik.isValidating || formik.isSubmitting}
        />
      </div>
    </form>
  )
}

export default withNamespaces()(InvestmentProfileForm)
