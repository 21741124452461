import React, { useState, useEffect, useRef } from 'react'
import './index.scss'
import { CompanyDetailsCard } from '../../../../stories/CompanyDetailsCard'
import FormattedDate from '../CompanyNotes/FormattedDate'
import { Button } from '@blueprintjs/core'
import { NewsType } from '../../../../logic/Backend'

interface Props {
  data?: NewsType[]
}

const CompanyNews = (props: Props) => {
  const { data } = props
  const [selectedArticle, setSelectedArticle] = useState<NewsType | null>(null)
  const popupRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      setSelectedArticle(null)
    }
  }

  useEffect(() => {
    if (selectedArticle) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [selectedArticle])
  return (
    <div className="news-list">
      <ul className="list">
        {data?.length === 0 && <>No news articles at this time.</>}
        {data
          ?.sort((a, b) => +new Date(b.published_date) - +new Date(a.published_date))
          .map((article, index) => {
            return (
              <li key={index} onClick={() => setSelectedArticle(article)}>
                <div className="news-list-box">
                  <p className="news-meta">
                    {article.source} • <FormattedDate date={new Date(article.published_date)} />
                  </p>
                  <p className="articleTitle">{article.title}</p>
                </div>
              </li>
            )
          })}
      </ul>
      {selectedArticle && (
        <div className="overlay">
          <div className="popup" ref={popupRef}>
            <h2>{selectedArticle.title}</h2>
            <p>
              <span>Source:</span> {selectedArticle.source}
            </p>
            <p>
              <span>By:</span> {selectedArticle.author}
            </p>
            <p className="articleContent">{addNewlineAfterEachNewline(selectedArticle.content.article)}</p>
            <div className="close-button">
              <Button onClick={() => setSelectedArticle(null)}>Close</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

function CompanyNewsCard(leftCardWidth: number, data?: NewsType[]) {
  return (
    <CompanyDetailsCard
      title="News"
      thingsToDisplay={[true]}
      flat={true}
      style={{
        position: 'absolute',
        height: '100%',
        width: `calc(${100 - leftCardWidth}% - 16px)`,
        left: `calc(${leftCardWidth}% + 16px)`,
        padding: '24px',
      }}
    >
      <CompanyNews data={data} />
    </CompanyDetailsCard>
  )
}

export default CompanyNewsCard

function addNewlineAfterEachNewline(inputString) {
  return inputString.replace(/\n/g, '\n\n')
}
